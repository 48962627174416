import React from "react";
import Meta from "../components/Meta";
import GeneratorSection from "../components/GeneratorSection";
import GallerySection from "../components/GallerySection";
import { requireAuth } from "../util/auth";

function GeneratorPaintPage(props) {
    document.title = "Virtual-Tryon - HasiAI";

  return (
    <>
      <Meta title="Try Clothes with AI" />
      <GeneratorSection
        model='tryon'
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Try Clothes with AI"
        subtitle="Revolutionize your style with AI."
        buttonAction="Generate"
        buttonColor="primary"
      />
    </>
  );
}

export default GeneratorPaintPage;
