import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";

function HeroSection(props) {
  const isMobile = props.isMobile

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Box textAlign="center">
          <SectionHeader
            title={props.title}
            smallTitle={props.smallTitle}
            subtitle={props.subtitle}
            size={isMobile ? 4 : 3}
          />
          <Box mb={isMobile ? 5 : 5} mt={isMobile ? 5 : 5}>
            <img 
                src={props.image} 
                style={{
                  maxWidth: isMobile ? '100%' : '90%', // Full width on mobile
                  maxHeight: isMobile ? '50vh' : '50vh', // Responsive height
                }}
              />
          </Box>
          <Button
            component={Link}
            to={props.buttonPath}
            variant="contained"
            size="large"
            color={props.buttonColor}
          >
            {props.buttonText}
          </Button>
        </Box>
      </Container>
    </Section>
  );
}

export default HeroSection;
