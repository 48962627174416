import React from "react";
import Meta from "../components/Meta";
import PricingCreditSection from "../components/PricingCreditSection";

function CreditPage(props) {
  return (
    <>
      <Meta title="Buy Credits - HasilAI.com" />
      <PricingCreditSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Buy Credits"
        subtitle="Affordable Plans for Everyone
        Whether you're an occasional user or a frequent artist, we've got you covered."
      />
    </>
  );
}

export default CreditPage;
