import React from "react";
import Meta from "../components/Meta";
import GeneratorSection from "../components/GeneratorSection";
import GallerySection from "../components/GallerySection";
import { requireAuth } from "../util/auth";

function GeneratorEditPage(props) {
    document.title = "Edit Image - HasiAI";

  return (
    <>
      <Meta title="Edit Image with AI" />
      <GeneratorSection
        model='edit'
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Edit Image with AI"
        subtitle="With just a sentence."
        buttonAction="Generate"
        buttonColor="primary"
      />
    </>
  );
}

export default GeneratorEditPage;
