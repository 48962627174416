import React from "react";
import Meta from "./../components/Meta";
import GeneratorSection from "./../components/GeneratorSection";
import GallerySection from "../components/GallerySection";
import { requireAuth } from "./../util/auth";

function GalleryPage(props) {
  document.title = "Gallery - HasilAI";

  return (
    <>
      <Meta />
      <GallerySection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Gallery"
        subtitle="Explore others."
      />
    </>
  );
}

export default GalleryPage;
