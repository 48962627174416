import React, { useState, useEffect } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import FormLabel from '@material-ui/core/FormLabel';
import { useForm } from "react-hook-form";
import { useAuth } from "./../util/auth";
import { Link, useRouter } from './../util/router.js';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import { useItem, updateItem, createItem, uploadImage, uploadImageFromURL, useItemsMutation } from "./../util/db";
import { apiRequest } from "../util/util";
import ImageUpload from "./ImageUpload";
import ColorizeIcon from '@material-ui/icons/Colorize';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import BrushIcon from '@material-ui/icons/Brush';
import FeaturesSection from "./FeaturesSection";

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

Array.prototype.sample = function(){
  return this[Math.floor(Math.random()*this.length)];
}

const generateSeed = () => {
  // Generate a random seed by reading 2 bytes from the operating system's random source
  // and interpreting them as an integer in big-endian order.
  
  // Generate 2 random bytes
  let randomBytes = new Uint8Array(2);
  window.crypto.getRandomValues(randomBytes);
  
  // Interpret the bytes as an integer in big-endian order
  const seed = (randomBytes[0] << 8) | randomBytes[1];
  return seed
}

const useStyles = makeStyles((theme) => ({
  media: {
    height: 160,
  },
  uploadIcon: {
    marginBottom: 10,
    fontSize: 40
  },
}));

function Generator(props) {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const [prediction, setPrediction] = useState(null);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);
  const [inputData, setInputData] = useState({
    mask_prompt: router.query.prompt || "clothes",
    negative_mask_prompt: "pants",
    adjustment_factor: 0,
    seed: 0,
    scale: 5,
    steps: 50,
    image: null,
    reference: null,
  })

  const handleSliderChange = (event, newValue) => {
    setInputData((prevData) => ({...prevData, editStrength: newValue }));
  };

  const handleInputChange = (event) => {
    const {value} = event.target
    setInputData((prevData) => ({...prevData, editStrength: value === ''? '' : Number(value) }));
  };

  // const handleBlur = () => {
  //   if (editStrength < 0) {
  //     setInputData((prevData) => ({...prevData, editStrength: 0 }));
  //   } else if (editStrength > 100) {
  //     setInputData((prevData) => ({...prevData, editStrength: 100 }));
  //   }
  // };

  const handleSwitch = (event) => {
    setShowAdvanced((prevData) => !prevData);
  }

  const handleImageUpload = (image) => {
    setInputData((prevData) => ({...prevData, image: image }));
  };

  const handleClothingUpload = (image) => {
    setInputData((prevData) => ({...prevData, reference: image }));
  };

  const {mask_prompt, negative_mask_prompt, adjustment_factor, seed, scale, steps, image, reference } = inputData
  
  const handleChange = (event) => {
    const {name, value} = event.target
    setInputData((prevData) => ({...prevData, [name]: value }));
  };
  
  // Handle form submission
  const generate = async () => {
    // Show pending indicator
    if (!auth.user) {
      router.push('/auth/signin')
      return
    }
    if (!auth.user.credit && auth.user.credit < 1){
      alert('Not enough credit')
      return
    }
    setPending(true);


    const seed = generateSeed()

    inputData.model = 'tryon'
    
    let prediction = await apiRequest("predictions", "POST", inputData);

    console.log(prediction)
  
    setPrediction(prediction);

    while (
      prediction.status !== "succeeded" &&
      prediction.status !== "failed"
    ) {
      await sleep(3000);
      const response = await fetch("/api/predictions/poll?" + new URLSearchParams({id: prediction.id}));
      prediction = await response.json();
      if (response.status !== 200) {
        setError(prediction.detail);
        return;
      }
      setPrediction(prediction);
    };
    setPending(false)
    if(prediction.status === "succeeded"){
      const imageURL = await uploadImageFromURL(prediction.output[4], `outputs/${auth.user.uid}_${new Date().getTime()}.png`)
      createItem({
        owner: auth.user.uid, 
        ownerName: auth.user.displayName,
        isPrivate: false,
        model: 'tryon',
        // isNSFW: prediction.output[0].nsfw,
        url: imageURL,
        ...prediction.input})
    }
    
  };

  const handleGenerate = () => {
    generate()
  }

  return (
    <form>
      <Grid container={true} spacing={2}>
        <Grid item={true} md={6} xs={12}>
          <ImageUpload 
            icon={<AccessibilityIcon className={classes.uploadIcon}/>}
            label="Upload Your Photo"
            subtitle="Drag or click to upload"
            onUpload={handleImageUpload}
          />
        </Grid>
        <Grid item={true} md={6} xs={12}>
          <ImageUpload
            icon={<AddPhotoAlternateIcon className={classes.uploadIcon}/>}
            label="Upload a Clothing Image"
            subtitle="Drag or click to upload"
            onUpload={handleClothingUpload}
          />
        </Grid>


        <Grid item={true} xs={12}>
          <FormControlLabel
            control={
              <Switch checked={showAdvanced} onChange={handleSwitch} name="showAdvanced" color="primary"/>
            }
            label="Show Advanced Options"
          />
        </Grid>
        
        { showAdvanced &&
        <Grid container spacing={2}>
          <Grid item={true} xs={12}>
            <TextField
              name="mask_prompt"
              variant="outlined"
              type="string"
              label="Mask Prompt"
              value={mask_prompt}
              onChange={handleChange}
              fullWidth={true}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TextField
              name="negative_mask_prompt"
              variant="outlined"
              type="string"
              label="Negative Mask Prompt"
              placeholder="pants"
              value={negative_mask_prompt}
              onChange={handleChange}
              fullWidth={true}
            />
          </Grid>
        </Grid>
        }
        
        
        <Grid item={true} xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleGenerate}
            disabled={pending}
            fullWidth={true}
          >
            {!pending && <span>{props.buttonAction}</span>}

            {pending && <CircularProgress size={28} />}
          </Button>
        </Grid>
        <Grid item={true} xs={12} style={{display:'flex'}} justifyContent="center">
          {prediction && prediction.status === 'succeeded' && <img src={prediction.output[4]} style={{maxHeight: 500}}/>}
        </Grid>
      </Grid>

      <FeaturesSection
            bgColor="default"
            size="medium"
            bgImage=""
            bgImageOpacity={1}
            items={[
              {
                title: "STEP 1",
                subtitle:
                  "Upload a photo of yourself or someone else..",
                icon: AccessibilityIcon,
                iconColor: "blackwhite",
              },
              {
                title: "STEP 2",
                subtitle:
                  'Upload a clothing image.',
                icon: AddPhotoAlternateIcon,
                iconColor: "blackwhite",
              },
              {
                title: "STEP 3",
                subtitle:
                  "Click 'Generate' and witness the AI magic.",
                icon: BrushIcon,
                iconColor: "blackwhite",
              },
            ]}
          />
    </form>
  );
}

export default Generator;
