import React, { useState, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import UploadIcon from '@material-ui/icons/CloudUpload';

const useStyles = makeStyles((theme) => ({
    uploadIcon: {
      marginBottom: 10,
      fontSize: 40
    },
}));

const ImageUpload = (props) => {
  const classes = useStyles();
  const [imageSrc, setImageSrc] = useState(null);
  const [dragging, setDragging] = useState(false);
  const dropRef = useRef(null);
  const fileInputRef = useRef(null);
  const [imageData, setImageData] = useState(null);

  const handleImageChange = async (e) => {
    e.preventDefault();
    let file;
    if (e.dataTransfer) {
      file = e.dataTransfer.files[0];
    } else {
      file = e.target.files[0];
    }
    if (file && file.type.match('image.*')) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setImageSrc(reader.result);
        setImageData(reader.result); // Store the base64 encoded data in state
        props.onUpload(reader.result)
        // Here you can send the base64 encoded image data to your backend
        // try {
        //   await axios.post('YOUR_BACKEND_ENDPOINT', {
        //     imageData: reader.result
        //   });
        // } catch (error) {
        //   console.error("Error uploading image:", error);
        // }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleImageChange(e);
      e.dataTransfer.clearData();
    }
  };

  const handleBoxClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div
      ref={dropRef}
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleDrop}
      onClick={handleBoxClick}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px',
        border: dragging ? '3px dashed #007BFF' : '3px solid #E5E7EB11',
        borderRadius: '8px',
        backgroundColor: dragging ? '#F3F4F633' : '#dddddd22',
        transition: 'border 0.2s, background-color 0.2s',
        width: '100%',
        height: '500px',
        maxHeight: '300px',
        cursor: 'pointer',
        textAlign: 'center',
        fontSize: '1em',
        
      }}
    >
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{
          display: 'none',
        }}
      />
      {imageSrc ? (
        <img src={imageSrc} alt="Uploaded Preview" style={{
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'contain',
          borderRadius: '6px'
        }} />
      ) : (
        <>
          { props.icon? props.icon : <UploadIcon className={classes.uploadIcon} /> }
          <div> {props.label? props.label : "Drag & Drop or Click to Upload" }</div>
          <div style={{ fontSize: '0.8em', marginTop: '10px' }}>{ props.subtitle? props.subtitle : "JPEG, PNG supported"}</div>
        </>
      )}
    </div>
  );
};

export default ImageUpload;
