import React from "react";
import Meta from "../components/Meta";
import AboutSection from "../components/AboutSection";

function AboutPage(props) {
  document.title = 'About HasilAI';

  return (
    <>
      <Meta title="About"/>
      <AboutSection
        bgColor="default"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
      />
    </>
  );
}

export default AboutPage;
