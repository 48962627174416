import React, { useState, useEffect } from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import GeneratorSection from "./../components/GeneratorSection";
import HeroSection2 from "./../components/HeroSection2";
import HeroSection3 from "./../components/HeroSection3";
import ContentCardsSection from "./../components/ContentCardsSection";
import FeaturesSection from "./../components/FeaturesSection";
import FaqSection from "./../components/FaqSection";
import { AgeVerification } from "../components/AgeVerification";




function IndexPage(props) {
  const ageVerified = localStorage.getItem('ageVerified');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <>
      <Meta />
      {/* {ageVerified !== 'true' && <AgeVerification />} */}
      <HeroSection
        bgColor="black"
        size="large"
        // bgImage="https://i.imgur.com/8L1xFWh.png"
        // bgImageOpacity={0.05}
        title="Revolutionize Your Style with AI"
        //smallTitle="Visualize how any clothing looks like on you"
        image={isMobile? "example4-fade.webp" : "main-example.webp"}
        subtitle="Visualize how any clothing looks like on you."
        buttonText="Get Started"
        buttonColor="primary"
        buttonPath="/create"
        isMobile={isMobile}
      />
      
      <HeroSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Upload and Try Any Clothes"
        subtitle=""
        image="example5-fade.webp"
        buttonText="Try on Clothes"
        buttonColor="primary"
        buttonPath="/create"
        isMobile={isMobile}
      />
      <HeroSection3
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Edit with a Sentence"
        subtitle=""
        image="example2.webp"
        buttonText="Edit Image"
        buttonColor="primary"
        buttonPath="/edit"
      />
      <HeroSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Convert Your post to Video Reels"
        subtitle=""
        image="article-to-video.webp"
        buttonText="Generate Video"
        buttonColor="primary"
        buttonPath="/video"
        isMobile={isMobile}
      />
      {/* <HeroSection2
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Perfect for Everyone"
        subtitle="Fashion enthusiasts, social media influencers, designers & retailers"
        image="https://waifu-files.faceplay.me/cdn-cgi/image/width=600,quality=85/https://files.soulgen.net/sg/home/images/desc-3-default.png"
        buttonText="Get Started"
        buttonColor="primary"
        buttonPath="/create"
      /> */}
      <FaqSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Frequently Asked Questions"
        subtitle=""
      />
    </>
  );
}

export default IndexPage;
