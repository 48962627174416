import React from "react";
import Meta from "../components/Meta";
import GeneratorSection from "../components/GeneratorSection";
import GallerySection from "../components/GallerySection";
import { requireAuth } from "../util/auth";

function GeneratorShortsPage(props) {
    document.title = "Edit Image - HasiAI";

  return (
    <>
      <Meta title="Generate Video Shorts with AI" />
      <GeneratorSection
        model='shorts'
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Generate Video Shorts with AI"
        subtitle="Convert article to video."
        buttonAction="Generate"
        buttonColor="primary"
      />
    </>
  );
}

export default GeneratorShortsPage;
