import React from "react";
import { Box, Typography,List,ListItem} from "@material-ui/core";

import LongContent from "./LongContent";

function LegalPrivacy(props) {
document.title = "Privacy Policy - HasilAI";

  return (
    <Box my={2}sx={{mt:"2px"}}>
       
    <Box
      sx={{
        width: 'inherit',
        display: 'flex',
        px:"2px",
        mb:"1.6rem",
        
        backgroundColor:"rgba(0, 0, 0, 0.1)",
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h4"gutterBottom
        sx={{
          color: 'white',
          fontWeight: 'bold',
        }}
      >
Privacy policy
      </Typography>
      <Typography
gutterBottom
        variant="body2"
        sx={{
          color: 'grey.500',
          fontWeight: 'medium',
          textAlign: 'right', // Align text to the right

          
        }}
      >
        Last updated on: 13/10/2023
      </Typography>

      <Box my={2} sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
        gutterBottom

          variant="h5" 
          sx={{
            color: 'white',
            fontWeight: 'medium',
          }}
        >
          Introduction
        </Typography>
        <Typography
        
          variant="body1"
          sx={{
            color: 'white',
          }}
        >
This Privacy Policy describes how we collect, use, and disclose personal information when you use our platform. We take your privacy seriously and are committed to safeguarding your personal information in accordance with applicable privacy laws. By accessing and using our platform, you consent to the collection, use, and disclosure practices outlined in this Privacy Policy.        </Typography>
      </Box>

      <Box my={2}sx={{ marginBottom: '1.2rem', display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="h5" gutterBottom

          sx={{
            color: 'white',
            fontWeight: 'medium',
            marginBottom: '0.6rem',
          }}
        >
Information Collection and Use        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: 'white',
          }}
        >
When you join our platform using third-party social apps such as Google, Twitter, Reddit, or Discord, we may collect certain personal information from your authorized account. This information may include your email address (if available) and username. We use this information to create and maintain your account and provide you with access to the platform's features. Should you make a purchase via CCBill, we store the necessary information we need for invoicing purposes, except for sensitive payment data. If you would like to know which data we collect specifically, please contact us via email. By utilizing the feature allowing users to upload images for the purpose of AI image recreation, you affirm that you possess the requisite legal rights and permissions pertaining to the uploaded images. Furthermore, you retain the authority to remove any uploaded images at your discretion.        </Typography>
      </Box>

      <Box my={2}sx={{ marginBottom: '1.2rem', display: 'flex', flexDirection: 'column' }}>
        <Typography
          variant="h5" gutterBottom

          sx={{
            color: 'white',
            fontWeight: 'medium',
            marginBottom: '0.6rem',
          }}
        >
Google Analytics        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: 'white',
          }}
        >
We use Google Analytics to analyze the usage of our platform. Google Analytics collects various types of information, including your IP address, browser type, device information, and usage data. This information helps us understand user behavior, improve our platform's functionality, and optimize user experience. Please note that Google Analytics operates independently and has its own privacy practices, which are governed by Google's Privacy Policy.        </Typography>
      </Box>

      <Box my={2}
      sx={{
        marginBottom: '1.2rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h5" gutterBottom
        sx={{
          color: 'white',
          fontWeight: 'medium',
          marginBottom: '0.6rem',
        }}
      >
Information Sharing and Disclosure      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: 'white',
        }}
      >
We do not sell, trade, or disclose your personal information to third parties for their marketing purposes. However, we may share your personal information in the following circumstances: With service providers who assist us in operating our platform and providing our services. These service providers are bound by confidentiality obligations and are only authorized to use your personal information as necessary to provide the requested services. In response to a legal request or to comply with applicable laws, regulations, or legal processes. To protect our rights, property, and safety, as well as the rights, property, and safety of our users or others.      </Typography>
    </Box>

    <Box my={2}
      sx={{
        marginBottom: '1.2rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h5" gutterBottom
        sx={{
          color: 'white',
          fontWeight: 'medium',
          marginBottom: '0.6rem',
        }}
      >
Data Retention      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: 'white',
        }}
      >
We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. We will securely delete or anonymize your personal information when it is no longer needed for the purposes for which it was collected.      </Typography>
    </Box>

    <Box my={2}
      sx={{
        marginBottom: '1.2rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h5" gutterBottom
        sx={{
          color: 'white',
          fontWeight: 'medium',
          marginBottom: '0.6rem',
        }}
      >
Children's Privacy      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: 'white',
        }}
      >
Our platform is intended for individuals who are at least 18 years old or the age of majority in their jurisdiction. We do not knowingly collect personal information from children under the age of 18. If we become aware that we have inadvertently collected personal information from a child under the age of 18, we will take steps to delete that information.      </Typography>
    </Box>

    <Box my={2}
      sx={{
        marginBottom: '1.2rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h5" gutterBottom
        sx={{
          color: 'white',
          fontWeight: 'medium',
          marginBottom: '0.6rem',
        }}
      >
Contact Us      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: 'white',
        }}
      >
If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your personal information, please contact us at support@hasilai.com.      </Typography>
    </Box>

   


    </Box>

    </Box>
  );
}

export default LegalPrivacy;
