import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Section from "./Section";
import { Link } from "../util/router";
import LongContent from "./LongContent";

function ContactSection(props) {
  const data = {
    domain: "company.com",
    companyName: "Company",
    email: "",
    phone: "",
    address: ""
  };

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
      <LongContent>
        <h1>Get in Touch</h1>
        <p>Have questions or feedback about HasilAI? Reach out to us.</p>
        
        <h2>Contact Details:</h2>
        <ul>
            <li><strong>Email:</strong> hello@hasilai.com</li>
            <li><strong>Mail:</strong> business@hasilai.com,</li>
            <li><strong>Support Mail:</strong> support@hasilai.com</li>
        </ul>
        
        <h2>Business & Affiliates Inquiries:</h2>
        <p>For partnerships, collaborations, or bulk pricing, please reach out to our business development team at business@hasilai.com.</p>
        
        <h2>Support Email:</h2>
        <p>Facing issues or need assistance with the platform? Our dedicated support team is here to help. Email us at support@hasilai.com or check out our FAQs for quick solutions.</p>
        
        <h2>Feedback:</h2>
        <p>Your feedback drives our growth. Share your experiences, suggestions, or reviews at hello@hasilai.com.</p>
        
        <h2>Stay Connected:</h2>
        <p>Follow us on Twitter, Instagram, and Facebook for the latest updates, offers, and art inspirations.</p>
      </LongContent>
      </Container>
    </Section>
  );
}

export default ContactSection;
