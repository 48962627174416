import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";

function PricingPage(props) {
  document.title = "Pricing - HasilAI's Plans and Packages";

  return (
    <>
      <Meta title="Pricing - HasilAI.com" />
      <PricingSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Pricing"
        subtitle="Affordable Plans for Every Desire
        Unlock the full potential of NSFW Art Generator with our flexible pricing plans. Whether you're an occasional user or a frequent artist, we've got you covered."
      />
    </>
  );
}

export default PricingPage;
