import React from "react";
import Container from "@material-ui/core/Container";
import { Alert } from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CheckIcon from "@material-ui/icons/Check";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import { useState } from "react";
import { Chip } from "@material-ui/core";
import { apiRequest } from "../util/util";
import PaypalSubscriptionButton from "./PaypalSubscriptionButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { redirectToBilling } from "./../util/stripe";

function PricingSection(props) {
  const router = useRouter();
  const auth = useAuth();

  const premiumBenefits = [
    "Upload custom clothes",
    "High Quality Images",
    "Priority Queue",
    "No WaterMark",
    "Fast response time"
  ];

  const plans = [
    {
      id: "plus",
        name: "Basic Plan",
        price: "4.99",
        actualPrice:"9.98",
        duration:"month",
        perks: [
          "120 Photo Edits per month",
          "Standard resolution",
        ],
    },
    {
      id: "premium",
        name: "Premium Plan",
        price: "37.99",
        actualPrice:"75.98",
        duration:"year",
        perks: [
          "990 Photo Edits per month",
          "High resolution",
          "Priority support",
        ],
    }
    
  ];

  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [pending, setPending] = useState(false);
  const [selectedPlan, setselectedPlan] = useState( plans[0]);


  // To test the selected order for payment on button click
  const handleConsole=(plan)=>{
    // console.log("your final placedd order",plan);
  }
  // To highlight the selected plan and to assign it to plan selected for payment
  const handleBoxClick = (plan) => {
    if (plan === "plus") {
      setselectedPlan({
        id: "plus",
        name: "Basic Plan",
        price: "9.99",
        actualPrice:"19.98",
        duration:"month",
        perks: [
          "120 Art Generations per month",
          "Access to all styles",
          "Standard resolution",
        ],
      });
      // console.log(selectedPlan)

    }
    if (plan === "premium") {
      setselectedPlan({
        id: "premium",
        name: "Premium Plan",
        price: "67.99",
        actualPrice:"135.98",
        duration:"yearly",
        perks: [
          "990 Art Generations per month",
          "Access to all styles and poses",
          "High resolution",
          "Priority support",
        ],
      });

    }
    // console.log(selectedPlan)

  };

  const handleCancel = async () => {
    setPending(true)
    try {
      if(auth.user && auth.user.subscriptionProvider=='stripe'){
        redirectToBilling().catch((error) => {
          setErrorMessage(error.message)
        });
        setPending(false)
      }
      else {
        console.log('before request')
        
        const response = await apiRequest("paypal-cancel-subscription", "POST", {
          subscriptionId: auth.user.paypalSubscriptionId
        });
        console.log('request sent')
        console.log(response)
        setPending(false)
        setMessage('Subscription cancelled')
      }
      

    } catch (error) {
      console.error(error);
      setErrorMessage(`Could not cancel paypal subscription...${error}`);
    }
  }


  const isBoxSelected = (boxName) => {
    // console.log("pane",boxName)
    return selectedPlan.id === boxName;
  };
  return (
<>
<Section>
  <Container><Typography variant="h5" align="center" gutterBottom style={{ color: "#C0C0C0" }}>
  Plans and Packages
</Typography>

<Typography variant="body1" align="center" gutterBottom style={{ color: "#C0C0C0" }}>  Explore our pricing plans. Whether you're an individual or a business, we offer a range of packages to suit your needs.
</Typography>
  </Container>
</Section>

<Box
      sx={{
        display: "flex",
        justifyContent: "center",
        marginX: "auto",
        background: "rgba(0, 0, 0, 0.17)"
      }}
    >
      
      <Box
        sx={{
          display: "flex",
          backgroundColor: "zinc.900", // Define your theme color
          border: "1px solid zinc.800", // Define your theme color
          borderRadius: "lg",
          maxWidth: { lg: "4xl", xl: "none" },
          marginY: "12px",
          gridTemplateColumns: { md: "repeat(3, 1fr)" },
          gap: "10px",
          marginX: "auto",
          padding: "12px",
          isolate: true
        }}
      >
        <Box
          id="left-side"
          sx={{
            display: { xs: "none", sm: "none", md: "block" },
            paddingTop: "12px",
            paddingBottom: "0",
            paddingLeft: "12px",
            maxWidth: "360px",
            width: "360px"
          }}
        >
          <Typography variant="h6"  sx={{ fontWeight: "bold", color: "pink" }}>
            Exciting Plans
            <br />
            Await You!!
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "pink", marginTop: "10px" }}
          >
            Up to{" "}
            <span sx={{ color: "pink", fontWeight: "bold", fontSize: "14px" }}>
              50%
            </span>{" "}
            off on subscription
          </Typography>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px"
            }}
          >
            <img
              src="/priceImg1.png"
              alt="Subscription"
              style={{ width: "100%", objectFit: "cover" }}
            />
          </Box> */}
        </Box>
{/* middle section */}
        <Box
          id="middle"
          sx={{
            paddingTop: "12px",
            paddingBottom: "0",
            paddingRight: "0",
            paddingLeft: "0",
            maxWidth: "360px",
            width: "360px"
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            {/* Exclusive benefit middlen sectio */}
            <Box
              sx={{
                display: { xs: "flex", sm: "flex", md: "none" },
                flexDirection: "column",
                gap: "6px"
              }}
            >
              <Box
                id="left-side"
                sx={{
                  paddingLeft: "6px",
                  paddingTop: "6px"
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: "pink", fontSize: "18px", fontWeight: "bold" }}
                >
                  Get Exclusive Discount!
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "white", fontSize: "sm", marginTop: "10px" }}
                >
                  Up to{" "}
                  <span
                    sx={{
                      color: "primary.main",
                      fontSize: "sm",
                      fontWeight: "medium"
                    }}
                  >
                    50%
                  </span>{" "}
                  off on subscription
                </Typography>
              </Box>
              <Box
                id="right"
                sx={{
                  paddingLeft: "6px"
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: "white",

                    fontSize: "16px",
                    fontWeight: "bold",
                    marginBottom: "6px"
                  }}
                >
                  Premium Benefits
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px"
                  }}
                >
                  {/* Premium Benefits List */}
                  {premiumBenefits.map((benefit, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "2",
                        flexDirection: "row"
                      }}
                    >
                      <img
                        src="/accept.png"alt="Check Mark"
                        style={{
                          width: "16px",
                          height: "16px",
                          marginRight: "8px"
                        }}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          color: "white",
                          fontSize: "sm",
                          fontWeight: "medium",
                          lineHeight: "tight"
                        }}
                      >
                        {benefit}
                      </Typography>
                    </Box>
                  ))}
                </Box>
               
              </Box>
            </Box>
            {/* Exclusive benefit ends heremiddlen sectio */}
            {/* Subscription Plan  */}

           

              {plans.map((plan) => (
        <Box
          key={plan.id}
          my={1}
          sx={{
            background: 'linear-gradient(90deg, #4d1d28 .9%, #4d1d2800)',
            boxShadow: 1,
            borderRadius: "10px",
            p: 3,
            border: `1px solid ${
              isBoxSelected(plan.id) ? 'lightblue' : 'var(--Pop-Up-Stroke, #282)'
            }`,
            minWidth: 250,
            position: "relative",
          }}
          onClick={() => handleBoxClick(plan.id)}
        >
          <Box
            sx={{
              position: "absolute",
              bottom: '0px',
              top: "-10px",
              left: "40px",
              zIndex: 'tooltip',
            }}
          >
            <Chip label="50% off" style={{ background: '#E95576' }} size="small" />
          </Box>
          <Box  display="flex" flexDirection="row" justifyContent="space-between">
            <Box
              sx={{
                color: 'text.secondary',
                fontWeight: '600',
                color: 'pink',
              }}
            >
              {plan.name}
            </Box>
          </Box>
          <Box my={1} sx={{ display: 'inline-flex', alignItems: 'center', gap: 1, color: 'white' }}>
            <Typography variant="h5" style={{ fontSize: '1.5rem', fontWeight: 'bold', gap: 2 }}>
              ${plan.price}
            </Typography>
            
            <Typography
              variant="body1"
              sx={{ fontSize: '0.875rem', fontWeight: 'medium', position: 'relative' }}
            >
             {"/"+plan.duration}
            </Typography>
            <Typography variant="caption"  style={{ color: "silver" }}sx={{ fontSize: '0.5rem', fontWeight: 'bold', gap: 2 }}>
             
          <s> {" "+"$"+plan.actualPrice}</s>{"/"+plan.duration}
            </Typography>
            
           
          </Box>
          <Box sx={{ color: 'silver', fontSize: 14 }}>
            {plan.perks.map((perk, index) => (
              <div key={index}>{perk}</div>
            ))}
          </Box>               
          <Box sx={{ color: 'silver', fontSize: 16 }}>Cancel anytime</Box>
        </Box>
      ))}   
      </Box>
{(auth.user && auth.user.planIsActive)
  ? 
  <div>
    <Button variant="contained" size='large' fullWidth={true} disabled> You already have {auth.user.planId == 'plus' ? 'Basic' : 'Premium'} plan </Button>
    <Button variant="text" color="primary" fullWidth={true} onClick={handleCancel}> 
       {!pending && <span>Cancel plan</span>} 
       {pending && <CircularProgress size={28} />}
    </Button>
  </div>
  :
  <div>
    <Button
      onClick={handleConsole(selectedPlan)}
      component={Link}
      to={
        auth.user
          ? `/purchase/${selectedPlan.id}`  // Use selectedPlan.id
          : `/auth/signup?next=/purchase/${selectedPlan.id}`  // Use selectedPlan.id
      }
      variant="contained"
      color="primary"
      size="large"
      fullWidth={true}
    >
      Pay with Credit/Debit Card
    </Button>
    {/* <PaypalSubscriptionButton planId={selectedPlan.id} setMessage={setMessage} setErrorMessage={setErrorMessage}/> */}
  </div>
}

{message && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              {message}
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
              </span>
            </Alert>
          </Box>
        )}

{errorMessage && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="error">
              {errorMessage}
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
              </span>
            </Alert>
          </Box>
        )}




{/* <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 0,
        maxHeight: 'auto',
      }}
    >
      <Box
        sx={{
          marginX: 'auto',
          marginTop: 10,
        }}
      >
        <Box
          sx={{
            display: 'inline-flex',
            gap: '20px',
          }}
        >
          <Box
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: '5px',
              opacity: 0.7, // Add opacity to the image and text
            }}
          >
            <img
              src="/shield.png"
              alt="Antivirus Secured"
              style={{ width: '40px', height: '36px' }}
            />
            <Typography
              variant="body1"
              sx={{
                color: 'text.secondary',
                fontSize: '1rem',
                fontWeight: 'bold',
                lineHeight: 1,
                margin: '0 0 0 5px', // Add margin to create space
                opacity: 0.7, // Add opacity to the text
              }}
            >
              Antivirus Secured
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: '5px', // Adjust the gap between image and text
              opacity: 0.7, // Add opacity to the image and text
            }}
          >
            <img
src="/privacy-policy.png"
alt="Privacy in bank statement"
              style={{ width: '40px', height: '36px' }}
            />
            <Typography
              variant="body1"
              sx={{
                color: 'text.secondary',
                fontSize: '1rem',
                fontWeight: 'bold',
                lineHeight: 1,
                margin: '0 0 0 5px', // Add margin to create space
                opacity: 0.7, // Add opacity to the text
              }}
            >
              Privacy in bank statement
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box> */}
    
    </Box>


          {/* payment buttons */}
            



        {/* right section */}
        <Box
  id="right"
  sx={{
    display: { xs: "none", sm: "none", md: "flex" },
    paddingTop: "12px",
    paddingBottom: "0px",
    paddingRight: "0px",
    paddingLeft: "20px",
    maxWidth: "360px",
    width: "360px",
    justifyContent: "space-around",
    flexDirection: "column",
  }}
>
          <Box
            sx={{
              display: { xs: "none", sm: "nene", md: "flex" },
              flexDirection: "column",
              gap: "6px"
            }}
          >
            <Box
              id="left-side"
              sx={{
                paddingLeft: "6px",
                paddingTop: "6px"
              }}
            >
             <Typography
  variant="h6"
  sx={{ fontSize: "18px", fontWeight: "bold" }}
>
  Get Exclusive Discount!
</Typography>

              <Typography
                variant="body2"
                sx={{ color: "white", fontSize: "sm", marginTop: "10px" }}
              >
                Up to{" "}
                <span
                  sx={{
                    color: "primary.main",
                    fontSize: "sm",
                    fontWeight: "medium"
                  }}
                >
                  50%
                </span>{" "}
                off on subscription
              </Typography>
            </Box>
            <Box
              id="right"
              sx={{
                paddingLeft: "6px"
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  color: "white",

                  fontSize: "16px",
                  fontWeight: "bold",
                  marginBottom: "6px"
                }}
              >
                Premium Benefits
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px"
                }}
              >
                {/* Premium Benefits List */}
                {premiumBenefits.map((benefit, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "2",
                      flexDirection: "row"
                    }}
                  >
                    <img
                      src="/accept.png"
                      alt="Check Mark"
                      style={{
                        width: "16px",
                        height: "16px",
                        marginRight: "8px"
                      }}
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        color: "white",
                        fontSize: "sm",
                        fontWeight: "medium",
                        lineHeight: "tight"
                      }}
                    >
                      {benefit}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          {/* <Box
            sx={{
              position:"relative",
              textAlign: "center",
              marginBottom: "6px",
            }}
          >
            <img
              src="/priceImg2.png"
              alt="Manga"
              style={{ width: "75%", objectFit: "cover",marginRight:"100px" }}
            />
          </Box> */}
         </Box>
        </Box>
      </Box>
</>
  );
}

export default PricingSection;
