import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import GeneratorPaintPage from "./generatorPaint";
import GeneratorEditPage from "./generatorEdit";
import GeneratorShortsPage from "./generatorShorts";
import PricingPage from "./pricing";
import DashboardPage from "./dashboard";
import ItemPage from "./item";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import AboutPage from "./about";
import { Switch, Route, Router } from "./../util/router";
import PurchasePage from "./purchase";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
import { AuthProvider } from "./../util/auth";
import { ThemeProvider } from "./../util/theme";
import { QueryClientProvider } from "./../util/db";
import FAQPage from "./faq";
import GalleryPage from "./gallery";
import ContactPage from "./contact";
import CreditPage from "./credit";

function App(props) {
  return (
    <QueryClientProvider>
      <ThemeProvider>
        <AuthProvider>
          <Router>
            <>
              <Navbar
                color="default"
                logo="/logo.png"
                logoInverted="/logo-inverted.png"
              />

              <Switch>
                <Route exact path="/" component={IndexPage} />
                
                <Route exact path="/create" component={GeneratorPaintPage} />

                <Route exact path="/edit" component={GeneratorEditPage} />

                <Route exact path="/video" component={GeneratorShortsPage} />

                <Route exact path="/gallery" component={GalleryPage} />

                <Route exact path="/images/:id" component={ItemPage} />

                <Route exact path="/pricing" component={PricingPage} />
                
                <Route exact path="/credit" component={CreditPage} />


                <Route exact path="/faq" component={FAQPage} />
                
                <Route exact path="/about" component={AboutPage} />

                <Route exact path="/contact" component={ContactPage} />

                <Route exact path="/dashboard" component={DashboardPage} />


                <Route exact path="/auth/:type" component={AuthPage} />

                <Route
                  exact
                  path="/settings/:section"
                  component={SettingsPage}
                />

                <Route exact path="/legal/:section" component={LegalPage} />

                <Route exact path="/purchase/:plan" component={PurchasePage} />

                <Route
                  exact
                  path="/firebase-action"
                  component={FirebaseActionPage}
                />

                <Route component={NotFoundPage} />
              </Switch>

              <Footer
                bgColor="default"
                size="medium"
                bgImage=""
                bgImageOpacity={1}
                description="Change clothes with AI"
                copyright={`© ${new Date().getFullYear()} Company`}
                logo="/logo.png"
                logoInverted="/logo-inverted.png"
                sticky={true}
              />
            </>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
