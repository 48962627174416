import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth } from "./../util/auth";
import { Link, useRouter } from './../util/router.js';
import Input from '@material-ui/core/Input';
import { useItem, updateItem, createItem, uploadImage, uploadImageFromURL, useItemsMutation } from "./../util/db";
import { apiRequest } from "../util/util";
import ImageUpload from "./ImageUpload";
import ColorizeIcon from '@material-ui/icons/Colorize';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import BrushIcon from '@material-ui/icons/Brush';
import StyleIcon from '@material-ui/icons/Style';
import FeaturesSection from "./FeaturesSection";

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

Array.prototype.sample = function(){
  return this[Math.floor(Math.random()*this.length)];
}

const generateSeed = () => {
  // Generate a random seed by reading 2 bytes from the operating system's random source
  // and interpreting them as an integer in big-endian order.
  
  // Generate 2 random bytes
  let randomBytes = new Uint8Array(2);
  window.crypto.getRandomValues(randomBytes);
  
  // Interpret the bytes as an integer in big-endian order
  const seed = (randomBytes[0] << 8) | randomBytes[1];
  return seed
}

const useStyles = makeStyles((theme) => ({
  media: {
    height: 160,
  },
}));

function Generator(props) {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const [prediction, setPrediction] = useState(null);
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);
  const [inputData, setInputData] = useState({
    prompt: router.query.prompt || "Change clothing to black color",
    image: null,
    editStrength: 50
  })

  const handleSliderChange = (event, newValue) => {
    setInputData((prevData) => ({...prevData, editStrength: newValue }));
  };

  const handleInputChange = (event) => {
    const {value} = event.target
    setInputData((prevData) => ({...prevData, editStrength: value === ''? '' : Number(value) }));
  };

  const handleBlur = () => {
    if (editStrength < 0) {
      setInputData((prevData) => ({...prevData, editStrength: 0 }));
    } else if (editStrength > 100) {
      setInputData((prevData) => ({...prevData, editStrength: 100 }));
    }
  };

  const handleUpload = (image) => {
    setInputData((prevData) => ({...prevData, image: image }));
  };

  const {prompt, image, editStrength} = inputData
  
  const handleChange = (event) => {
    const {name, value} = event.target
    setInputData((prevData) => ({...prevData, [name]: value }));
  };
  
  // Handle form submission
  const generate = async () => {
    // Show pending indicator
    if (!auth.user) {
      router.push('/auth/signin')
      return
    }
    if (!auth.user.credit && auth.user.credit < 1){
      alert('Not enough credit')
      return
    }
    setPending(true);


    const seed = generateSeed()
    const inputData = {
      prompt: prompt,
      image: image,
      image_guidance_scale: 2 - (editStrength/100), 
      seed: seed,
      model: 'edit'
    }
    
    let prediction = await apiRequest("predictions", "POST", inputData);

    console.log(prediction)
  
    setPrediction(prediction);

    while (
      prediction.status !== "succeeded" &&
      prediction.status !== "failed"
    ) {
      await sleep(3000);
      const response = await fetch("/api/predictions/poll?" + new URLSearchParams({id: prediction.id}));
      prediction = await response.json();
      if (response.status !== 200) {
        setError(prediction.detail);
        return;
      }
      setPrediction(prediction);
    };
    setPending(false)
    if(prediction.status === "succeeded"){
      const imageFilename = prompt.replace(/[^a-z0-9]/gi, '_').toLowerCase();
      const imageURL = await uploadImageFromURL(prediction.output[0].image, `outputs/${auth.user.uid}_${imageFilename}_${new Date().getTime()}.png`)
      createItem({
        owner: auth.user.uid, 
        ownerName: auth.user.displayName,
        userPrompt: prompt,
        isPrivate: true,
        // isNSFW: prediction.output[0].nsfw,
        url: imageURL,
        ...prediction.input})
    }
    
  };

  const handleGenerate = () => {
    generate()
  }

  return (
    <form>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <TextField
            name="prompt"
            variant="outlined"
            type="string"
            label="Prompt"
            value={prompt}
            onChange={handleChange}
            fullWidth={true}
          />
        </Grid>
        {/* <Grid item={true} xs={12}>
          <TextField
            name="negativePrompt"
            variant="outlined"
            type="string"
            label="Negative Prompt"
            placeholder="ugly, hat, earring"
            value={negativePrompt}
            onChange={handleChange}
            fullWidth={true}
          />
        </Grid> */}
        <Grid item={true} xs={12}>
          <Typography id="input-slider" style={{fontSize: 14, marginLeft: 6}} gutterBottom>
          Edit Strength
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <ColorizeIcon />
          </Grid>
          <Grid item xs>
            <Slider
              value={typeof editStrength === 'number' ? editStrength : 0}
              onChange={handleSliderChange}
              aria-labelledby="input-slider"
            />
          </Grid>
          <Grid item>
            <Input
              className={classes.input}
              value={editStrength}
              margin="dense"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: 10,
                min: 0,
                max: 100,
                type: 'number',
                'aria-labelledby': 'input-slider',
              }}
            />
          </Grid>
        </Grid>
        </Grid>
        <Grid item={true} xs={12}>
          <ImageUpload onUpload={handleUpload}/>
        </Grid>

        
        {/* <Box clone mt={1} mb={3}>
          <Container>
            <Grid container={true} spacing={4} justifyContent="center">
              {modelStyles.map((item, index) => (
                    <Grid item={true} xs={6} md={3} key={index}>
                      <Card >
                        <CardActionArea onClick={() => handleStyleClick(item.value)}>
                          <CardMedia
                            style={{height: 130}}
                            image={item.image}
                            className={classes.media}
                          />
                          <Box
                            sx={{
                              position: 'absolute',
                              bottom: 0,
                              left: 0,
                              width: '100%',
                              bgcolor: item.value === style ? 'primary.dark' : 'rgba(0, 0, 0, 0.54)',
                              color: 'white',
                              padding: '5px',
                            }}
                          >
                            <Typography variant="caption">{item.text}</Typography>
                          </Box>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
            </Grid>
          </Container>
        </Box>

        <Grid item={true} xs={12} container={true} spacing={4} justifyContent="space-between">
          <Grid item={true}>
            <FormControl variant="outlined">
              <InputLabel id="style-label">Style</InputLabel>
              <Select
                name="style"
                onChange={handleChange}
                value={style}
                labelId="style-label"
                id="style-select"
                style={{width: 240}}
              >
                {modelStyles.map((item, index) => (
                  <MenuItem value={item.value}>{item.text}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item={true}>
            <FormControl variant="outlined">
              <InputLabel id="pose-label">Pose</InputLabel>
              <Select
                name="pose"
                onChange={handleChange}
                value={pose}
                labelId="pose-label"
                id="pose-select"
                style={{width: 240}}
              >
                {modelPoses.map((item, index) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
        </Grid> */}

        {/* <Grid item={true} xs={12}>
          <FormControlLabel
            control={
              <Switch checked={isPrivate} onChange={handleSwitch} name="isPrivate" color="primary"/>
            }
            label="Keep image private?"
          />
        </Grid> */}
        
        <Grid item={true} xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleGenerate}
            disabled={pending}
            fullWidth={true}
          >
            {!pending && <span>{props.buttonAction}</span>}

            {pending && <CircularProgress size={28} />}
          </Button>
        </Grid>
        <Grid item={true} xs={12} style={{display:'flex'}} justifyContent="center">
          {prediction && prediction.status === 'succeeded' && <img src={prediction.output} style={{maxHeight: 500}}/>}
        </Grid>
      </Grid>

      <FeaturesSection
            bgColor="default"
            size="medium"
            bgImage=""
            bgImageOpacity={1}
            items={[
              {
                title: "STEP 1",
                subtitle:
                  "Upload a photo of yourself or someone else..",
                icon: AccessibilityIcon,
                iconColor: "blackwhite",
              },
              {
                title: "STEP 2",
                subtitle:
                  'Type your instruction e.g "Make him wear a suit"',
                icon: StyleIcon,
                iconColor: "blackwhite",
              },
              {
                title: "STEP 3",
                subtitle:
                  "Click 'Generate' and witness the AI magic.",
                icon: BrushIcon,
                iconColor: "blackwhite",
              },
            
            ]}
          />
    </form>
  );
}

export default Generator;
