import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import GeneratorPaint from "./GeneratorPaint";
import GeneratorEdit from "./GeneratorEdit";
import GeneratorShorts from "./GeneratorShorts";
import FeaturesSection from "./FeaturesSection";
import { Link, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import StyleIcon from '@material-ui/icons/Style';
import BrushIcon from '@material-ui/icons/Brush';
import ImageIcon from '@material-ui/icons/Image';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';

const paintFeatures = [
  {
    title: "STEP 1",
    subtitle:
      "Upload a photo of yourself or someone else..",
    icon: AccessibilityIcon,
    iconColor: "blackwhite",
  },
  {
    title: "STEP 2",
    subtitle:
      'Upload a clothing image.',
    icon: AddPhotoAlternateIcon,
    iconColor: "blackwhite",
  },
  {
    title: "STEP 3",
    subtitle:
      "Click 'Generate' and witness the AI magic.",
    icon: BrushIcon,
    iconColor: "blackwhite",
  },
]

const editFeatures = [
  {
    title: "STEP 1",
    subtitle:
      "Upload a photo of yourself or someone else..",
    icon: AccessibilityIcon,
    iconColor: "blackwhite",
  },
  {
    title: "STEP 2",
    subtitle:
      'Type your instruction e.g "Make him wear a suit"',
    icon: StyleIcon,
    iconColor: "blackwhite",
  },
  {
    title: "STEP 3",
    subtitle:
      "Click 'Generate' and witness the AI magic.",
    icon: BrushIcon,
    iconColor: "blackwhite",
  },
]

function GeneratorSection(props) {
  const auth = useAuth();
  const router = useRouter();
  const model = props.model? props.model : 'tryon'

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container maxWidth="sm">
      {router.query.paid && auth.user?.planIsActive && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You are now subscribed to the {auth.user?.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}
        {router.query.paid && router.query.credit && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You successfully bought {router.query.credit} credit!
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        )}    
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
         {
          model === 'tryon' ?
            <GeneratorPaint
              buttonAction={props.buttonAction}
              providers={props.providers}
            />
            :
            (model === 'shorts' ?
              <GeneratorShorts
                buttonAction={props.buttonAction}
                providers={props.providers}
              />
              :
              <GeneratorEdit
                buttonAction={props.buttonAction}
                providers={props.providers}
              />
            )
        }
        
        
      </Container>
    </Section>
  );
}

export default GeneratorSection;
